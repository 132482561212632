import { getLocalizedString, getLanguage } from "@icarius-localization/strings";
import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import {
    Dialog,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import "@icarius-assets/css/grid-dark.scss";
import "@icarius-assets/css/grid-light.scss";
import { AgGridReact } from "@ag-grid-community/react";
import {
    decimalStatusBar,
    setGridName,
    setLocale,
    setCtx,
    agGridLanguage,
    defaultColDef,
    onGridReady,
    setTaskControl,
} from "@icarius-table/utils";
import { excelStyles } from "@icarius-table/excelStyles";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import moment from "moment";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

class CoincidenceDialog extends React.Component {

    constructor(props) {
        super(props);
        this.agGridRef = React.createRef();
    }

    generateragCellClassRules = (nombre) => {
        const data = {
            'rag-amber': function (params) {
                return params.value === "-";
            },
            'rag-blue-whiteText': function (params) {
                return params.value === "Licencia" || params.value === "Permiso" || params.value === "Vacaciones";
            },
            'rag-member': function (params) {
                return params.value === nombre
            },
            'rag-blue': function (params) {
                return params.value === ".";
            },
        }

        return data;
    }
    generateColumns(from, to, ragCellClassRules) {
        let fromDate = new Date(from.replace(/(\d+)\/(\d+)/, '$2/$1'));
        let toDate = new Date(to.replace(/(\d+)\/(\d+)/, '$2/$1'));
        fromDate.setDate(fromDate.getDate() - 1);
        toDate.setDate(toDate.getDate() + 1);

        let columnDefinitions = [
            {
                headerName: "Colaborador",
                field: "NOMBRE",
                filter: "agTextColumnFilter",
                cellClass: "stringType",
                cellClassRules: ragCellClassRules,
            }
        ];

        while (toDate >= fromDate) {
            columnDefinitions.push(
                {
                    headerName: ('0' + fromDate.getDate()).slice(-2) + "/" + ('0' + (fromDate.getMonth() + 1)).slice(-2) + '/' + fromDate.getFullYear(),
                    field: ('0' + fromDate.getDate()).slice(-2) + "/" + ('0' + (fromDate.getMonth() + 1)).slice(-2) + '/' + fromDate.getFullYear(),
                    cellClass: "stringType",
                    cellClassRules: ragCellClassRules,
                    floatingFilter: false,
                    filter: false,
                    rowGroup: false,
                    suppressMenu: true,
                    suppressMovable: true,
                }
            );

            fromDate.setDate(fromDate.getDate() + 1)
        }

        return columnDefinitions;
    }

    generateDataForMember = (types, persona, isPetitioner) => {
        const data = { "NOMBRE": persona.NOMBRE };
        const caracter = isPetitioner ? "-" : ".";
        const startDate = new Date(this.props.data.start_date.replace(/(\d+)\/(\d+)/, '$2/$1'));
        const endDate = new Date(this.props.data.end_date.replace(/(\d+)\/(\d+)/, '$2/$1'));

        persona.PERIODOS.forEach(element => {
            let firstColumn = false;
            let fromDate = new Date(element.from.replace(/(\d+)\/(\d+)/, '$2/$1'));
            let toDate = new Date(element.to.replace(/(\d+)\/(\d+)/, '$2/$1'));

            if (moment(startDate).subtract(1, 'days').toDate() > fromDate) {
                fromDate = moment(startDate).subtract(1, 'days').toDate();
            }

            if (moment(endDate).add(1, 'days').toDate() < toDate) {
                toDate = moment(endDate).add(1, 'days').toDate();
            }

            while (toDate >= fromDate) {

                if (!firstColumn && element.type) {
                    const type = types.find(type => type.id === element.type);
                    data[('0' + fromDate.getDate()).slice(-2) + "/" + ('0' + (fromDate.getMonth() + 1)).slice(-2) + '/' + fromDate.getFullYear()] = type.value;
                    firstColumn = true;
                } else {
                    data[('0' + fromDate.getDate()).slice(-2) + "/" + ('0' + (fromDate.getMonth() + 1)).slice(-2) + '/' + fromDate.getFullYear()] = caracter;
                }
                fromDate.setDate(fromDate.getDate() + 1)
            }
        });

        return data;
    }
    generateData = (types, licencePeriods) => {
        let members = [];

        licencePeriods.forEach(element => {
            members.push(this.generateDataForMember(types, element));
        });

        return members;
    }

    render() {
        const { open, color, dateFormat, locale, gridTheme, types, licencePeriods, data } = this.props;
        const startDate = data.start_date;
        const endDate = data.end_date;

        const nombreSolicitante = data.employee_name + " (Solicitante)";

        const ragCellClassRules = this.generateragCellClassRules(nombreSolicitante);

        this.rowData = this.generateData(types, licencePeriods);

        this.data = [this.generateDataForMember(types, { "NOMBRE": nombreSolicitante, "PERIODOS": [{ from: startDate, to: endDate }] }, true)]
        this.columnDefs = this.generateColumns(startDate, endDate, ragCellClassRules);


        setGridName(getLocalizedString("alerts"));
        setLocale(locale);
        setCtx(this.agGridRef);
        setTaskControl(false);

        const StyledDialog = withStyles({
            paper: {
                maxWidth: "85%",
                minWidth: "85%",
            },
        })(Dialog);

        return (
            <StyledDialog
                TransitionComponent={DialogTransition}
                PaperComponent={PaperDraggable}
                open={open}
                fullWidth={true}
                maxWidth={"md"}>
                <div className={"dialog-container"}>
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {`${getLocalizedString("matches")} - ${this.props.requestType}, ${this.props.data.duration_days} ${getLocalizedString("days")}`}
                    </DialogTitle>
                    <CloseIcon className={"dialog-close-icon icon"} onClick={() => this.handleClose(false)} />
                    <DialogContent>
                        <div
                            className={
                                gridTheme.theme === "dark" ? "ag-theme-balham-dark" : "ag-theme-balham"
                            }
                            style={{ height: "65vh" }}>
                            <AgGridReact
                                ref={this.agGridRef}
                                columnDefs={this.columnDefs}
                                rowData={this.rowData}
                                defaultColDef={defaultColDef}
                                localeText={getLanguage() !== "es" ? undefined : agGridLanguage.es}
                                getContextMenuItems={[]}
                                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                                excelStyles={excelStyles(color, dateFormat)}
                                onGridReady={onGridReady}
                                pinnedTopRowData={this.data}
                                modules={AllModules}></AgGridReact>
                        </div>
                    </DialogContent>
                </div>
            </StyledDialog>
        );
    }


    onFirstDataRendered(params) {

        //Seteo los colores corporativos
        document.documentElement.style.setProperty("--main-bg-color", this.props.color);
        if (this.props.darkerColor) {
            document.documentElement.style.setProperty("--grid-bg-color", this.props.darkerColor);
        }

        if (this.props.lighterColor) {
            document.documentElement.style.setProperty("--grid-light-color", this.props.lighterColor);
        }

        params.api.addEventListener("rangeSelectionChanged", decimalStatusBar);
        params.api.addEventListener("filterChanged", decimalStatusBar);
        params.api.addEventListener("filterModified", decimalStatusBar);
        params.api.addEventListener("sortChanged", decimalStatusBar);
        params.api.addEventListener("displayedColumnsChanged", decimalStatusBar);
    }

    componentWillUnmount() {
        if (this.agGridRef?.current) {
            this.agGridRef.current.api.removeEventListener("rangeSelectionChanged", decimalStatusBar);
            this.agGridRef.current.api.removeEventListener("filterChanged", decimalStatusBar);
            this.agGridRef.current.api.removeEventListener("filterModified", decimalStatusBar);
            this.agGridRef.current.api.removeEventListener("sortChanged", decimalStatusBar);
            this.agGridRef.current.api.removeEventListener("displayedColumnsChanged", decimalStatusBar);
        }
    }

    handleClose = (shouldComponentUpdate) => {
        this.props.handleCloseDialog && this.props.handleCloseDialog(shouldComponentUpdate);
    };
}

export default connect()(CoincidenceDialog);
