import NAME from "./constants";

export const loading = store => store[NAME].gettingMyPending || store[NAME].modifyingRequest || store[NAME].derivatingRequest;

export const getTypesAdvances = store => store[NAME].typesAdvances;
export const getMyPending = store => store[NAME].requestRows;
export const getFireCalculationData = store => store[NAME].fireCalculationData;
export const getRaiseCalculationData = store => store[NAME].raiseCalculationData;
export const getAdvanceCalculationData = store => store[NAME].advanceCalculationData;
export const getEmployers = store => store[NAME].employers;
export const getRecipients = store => store[NAME].recipients;
export const getApprovants = store => store[NAME].approvants;
export const getDecimalSeparator = store => store[NAME].decimalSeparator;
export const getLocale = store => store[NAME].currencyLocalization;
export const getDateFormat = store => store[NAME].dateFormat;