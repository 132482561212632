import {
  GET_MY_PENDING,
  GET_MY_PENDING_FULFILLED,
  GET_MY_PENDING_REJECTED,
  MODIFY_REQUEST_STATUS_REJECTED,
  MODIFY_REQUEST_STATUS_FULFILLED,
  MODIFY_REQUEST_STATUS,
  DERIVATE_REQUEST,
  DERIVATE_REQUEST_FULFILLED,
  DERIVATE_REQUEST_REJECTED
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getMyPendingEmployerAPI,
  derivateManagerAppealAPI,
  modifyRaiseAppealStatusAPI,
  modifyFireAppealStatus,
  modifyAdvanceAppealStatusAPI
} from "@icarius-connection/api";
import { getLocalizedString } from "@icarius-localization/strings";
import { requestsIds, getRequestSnackbarText } from "@icarius-utils/requestsIds";
import { openDialogAction } from "@icarius-common/dialog/actions";

export const getMyPendingRows = (includesHistory) => async (dispatch) => {
  dispatch({ type: GET_MY_PENDING });
  try {
    let response = await getMyPendingEmployerAPI(`?include_historicals=${includesHistory}`);

    if (response.data.status === "OK") {

      let typesAdvances = response.data.types_advances;
      let requestRows = response.data.result;
      let employers = response.data.employers;
      let fireCalculationData = response.data.fire_calculation_data;
      let raiseCalculationData = response.data.raise_calculation_data;
      let advanceCalculationData = response.data.advances_calculation_data;

      let decimalSeparator = response.data.decimal_separator;
      let currencyLocalization = response.data.currency_localization;
      let dateFormat = response.data.date_format;

      let recipients = response.data.recipients;
      let approvants = response.data.approvants;

      dispatch({
        type: GET_MY_PENDING_FULFILLED,
        payload: {
          requestRows,
          employers,
          fireCalculationData,
          raiseCalculationData,
          advanceCalculationData,
          decimalSeparator,
          currencyLocalization,
          dateFormat,
          typesAdvances,
          recipients,
          approvants,
        },
      });
    }

    return response;
  } catch (e) {
    dispatch({ type: GET_MY_PENDING_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const derivateRequest = (data) => async (dispatch) => {
  dispatch({ type: DERIVATE_REQUEST });
  try {
    let response = await derivateManagerAppealAPI(data);

    if (response.data.status === "OK") {
      dispatch({ type: DERIVATE_REQUEST_FULFILLED });
      dispatch(openSnackbarAction({ msg: getLocalizedString("derivationSuccessfull"), severity: "success" }));
    } else {
      dispatch({ type: DERIVATE_REQUEST_REJECTED });
    }
    return response;
  } catch (e) {
    dispatch({ type: DERIVATE_REQUEST_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const changeRequestStatus = (data, type) => async (dispatch) => {
  dispatch({ type: MODIFY_REQUEST_STATUS });
  try {
    let response;
    switch (type) {
      case requestsIds.id_type.RS:
        response = await modifyRaiseAppealStatusAPI(data);
        break;
      case requestsIds.id_type.FR:
        response = await modifyFireAppealStatus(data);
        break;
      case requestsIds.id_type.ANT:
        response = await modifyAdvanceAppealStatusAPI(data);
        break;
      default:
        dispatch({ type: MODIFY_REQUEST_STATUS_REJECTED });
        return;
    }

    if (response.data.status === "OK") {
      if (type === requestsIds.id_type.FR) {
        if (response.data.were_multiple_severances_done) {
          dispatch(
            openDialogAction({
              msg: getLocalizedString("multipleSeverances"),
              title: getLocalizedString("atention"),
              acceptOnly: true,
            })
          );
        }
        else {
          dispatch(openSnackbarAction({ msg: getRequestSnackbarText(data.id_status), severity: "success" }));
        }
      } else {
        dispatch(openSnackbarAction({ msg: getRequestSnackbarText(data.id_status), severity: "success" }));
      }

      dispatch({ type: MODIFY_REQUEST_STATUS_FULFILLED });
    } else {
      dispatch({ type: MODIFY_REQUEST_STATUS_REJECTED });
      dispatch(openSnackbarAction({ msg: "Ha ocurrido un error", severity: "error" }));
      return;
    }
    return response;
  } catch (e) {
    dispatch({ type: MODIFY_REQUEST_STATUS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};