import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import { previewFromURL } from "@icarius-utils/download";
import {
  getIsLoading,
  getProcessList,
  getMomentList,
  getActionTypeList,
  getEmployeeList,
  getProgressData,
  getComments,
  getFiles,
} from "../selectors";
import { RESET_COMMENTS, RESET_FILES, RESET_STATE } from "../actionTypes";
import {
  getMyOnboardingTrackingAction,
  getActionsMyOnboardingTrackingAction,
  getMomentsMyOnboardingTrackingAction,
  getProgressMyOnboardingTrackingAction,
  updateMaterialMyOnboardingTrackingAction,
  getMyOnboardingTrackingActionCommentsAction,
  getMyOnboardingTrackingActionFilesAction,
} from "../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { openPollAction } from "@icarius-common/poll/actions";

const useMyOnboardingTracking = () => {

  const [selectedProcess, setSelectedProcess] = useState("");
  const [selectedMoment, setSelectedMoment] = useState("");
  const [selectedActionType, setSelectedActionType] = useState("");
  const [boardData, setBoardData] = useState([]);
  const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedConsent, setSelectedConsent] = useState(null);

  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const processList = useSelector(getProcessList);
  const momentList = useSelector(getMomentList);
  const actionTypeList = useSelector(getActionTypeList);
  const progressData = useSelector(getProgressData);
  const employeeList = useSelector(getEmployeeList);
  const comments = useSelector(getComments);
  const files = useSelector(getFiles);

  useEffect(() => {
    dispatch(getMyOnboardingTrackingAction());

    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleFetchProgressData = (process, moment) => {
    dispatch(getProgressMyOnboardingTrackingAction(process, moment));
  }

  const handleChangeProcess = (newProcess) => {
    setSelectedProcess(newProcess);
    setSelectedMoment("");
    setSelectedActionType("");
    setBoardData([]);

    dispatch(getMomentsMyOnboardingTrackingAction(newProcess))
      .then((resp) => {
        if (resp.status === 200) {
          const currentMoment = resp.data.momentList.find((item) => !item.completed) || resp.data.momentList.at(-1);
          handleChangeMoment(newProcess, currentMoment.key);
        }
      });
  }

  const handleChangeMoment = (process, newMoment) => {
    setSelectedMoment(newMoment);
    setSelectedActionType("");
    handleFetchActions(process, newMoment);
    handleFetchProgressData(process, newMoment);
  }

  const handleFetchActions = (processCode, momentCode) => {
    if (processCode && momentCode) {
      dispatch(getActionsMyOnboardingTrackingAction(processCode, momentCode))
        .then((resp) => {
          if (resp.status === 200) {
            setBoardData(resp.data?.actionList || []);
          }
        })
    }
  }

  const handleChangeActionType = (newActionType) => setSelectedActionType(newActionType);

  const handleOpenViewActionDialog = (newSelectedAction) => {
    dispatch(getMyOnboardingTrackingActionFilesAction(newSelectedAction.code))
      .then((res) => {
        if (res?.status === 200) {
          dispatch(getMyOnboardingTrackingActionCommentsAction(newSelectedAction.code))
            .then((res) => {
              if (res?.status === 200) {
                setSelectedAction(newSelectedAction);
                setViewDialogIsOpen(true);
              }
            });
        }
      });
  }

  const handleCloseViewActionDialog = () => {
    setViewDialogIsOpen(false);
    setSelectedAction(null);
    dispatch({ type: RESET_FILES });
    dispatch({ type: RESET_COMMENTS });
  }

  const handleOpenMaterial = (material, action) => {
    const dataToSend = {
      processCode: state.selectedProcess,
      momentCode: state.selectedMoment,
      actionCode: action.type,
      number: action.number,
      materialCode: material.code,
    };

    const onlyGuide = Boolean(!material.poll && !material.consent && !material.link && !material.file);

    const openMaterial = () => {
      if (material.poll) {
        dispatch(openPollAction(material.poll.code, material.poll.text, material.poll.type));
      }

      if (material.consent) {
        setSelectedConsent(material.consent);
      }

      if (material.link) {
        previewFromURL(material.link);
      }

      if (material.file) {
        previewFromURL(RESOURCES_ENDPOINT + material.file.path)
      }

      dispatch(updateMaterialMyOnboardingTrackingAction(dataToSend))
        .then((resp) => {
          if (resp.status === 200) {
            setBoardData(resp.data?.actionList || []);
          }
        })
    }

    if (material.guide) {
      dispatch(openDialogAction({
        title: material.name,
        msg: material.guide,
        onConfirm: openMaterial,
        acceptText: onlyGuide ? "Cerrar" : "Acceder a material",
        acceptOnly: true,
        maxWidth: "md",
        fullWidth: true,
      }));

      return;
    }

    openMaterial();
  }

  const handleCloseConsentDialog = () => {
    setSelectedConsent(null);
  }

  const state = {
    selectedProcess,
    selectedMoment,
    selectedActionType,
    boardData,
    selectedAction,
    selectedConsent,
    viewDialogIsOpen,
  }

  const handlers = {
    handleChangeProcess,
    handleChangeMoment,
    handleChangeActionType,
    handleOpenViewActionDialog,
    handleCloseViewActionDialog,
    handleOpenMaterial,
    handleCloseConsentDialog,
  }

  const kanbanColumnDefinition = [
    {
      code: "P",
      name: "Planificado",
    },
    {
      code: "C",
      name: "En curso",
    },
    {
      code: "R",
      name: "Realizado",
    },
  ];

  const filteredMomentList = useMemo(() => {
    // devuelvo los momentos completos y el primero sin completar
    const firstNotCompletedMomentIndex = momentList.findIndex((item) => !item.completed);
    if (firstNotCompletedMomentIndex === -1) return momentList;
    return momentList.slice(0, firstNotCompletedMomentIndex + 1);
  }, [momentList]);

  const filteredActionTypeList = useMemo(() => {
    if (!selectedMoment || !boardData?.length) return actionTypeList;

    return actionTypeList.filter((actionType) => boardData.some((action) => action.type === actionType.key));
  }, [actionTypeList, selectedMoment, boardData]);

  return {
    isLoading,
    state,
    handlers,
    processList,
    momentList: filteredMomentList,
    actionTypeList: filteredActionTypeList,
    actionList: boardData,
    comments,
    files,
    progressData,
    employeeList,
    kanbanColumnDefinition,
  };
}

export default useMyOnboardingTracking;