import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const GET_MOMENTS = NAME + "/GET_MOMENTS";
export const GET_MOMENTS_REJECTED = NAME + "/GET_MOMENTS_REJECTED";
export const GET_MOMENTS_FULFILLED = NAME + "/GET_MOMENTS_FULFILLED"

export const GET_PROGRESS = NAME + "/GET_PROGRESS";
export const GET_PROGRESS_REJECTED = NAME + "/GET_PROGRESS_REJECTED";
export const GET_PROGRESS_FULFILLED = NAME + "/GET_PROGRESS_FULFILLED"

export const GET_ACTIONS = NAME + "/GET_ACTIONS";
export const GET_ACTIONS_REJECTED = NAME + "/GET_ACTIONS_REJECTED";
export const GET_ACTIONS_FULFILLED = NAME + "/GET_ACTIONS_FULFILLED";

export const UPDATE_MATERIAL = NAME + "/UPDATE_MATERIAL";
export const UPDATE_MATERIAL_REJECTED = NAME + "/UPDATE_MATERIAL_REJECTED";
export const UPDATE_MATERIAL_FULFILLED = NAME + "/UPDATE_MATERIAL_FULFILLED";

export const ACCEPT_MY_CONSENT = NAME + "/ACCEPT_MY_CONSENT";
export const ACCEPT_MY_CONSENT_REJECTED = NAME + "/ACCEPT_MY_CONSENT_REJECTED";
export const ACCEPT_MY_CONSENT_FULFILLED = NAME + "/ACCEPT_MY_CONSENT_FULFILLED";

export const PREVIEW_MY_CONSENT = NAME + "/PREVIEW_MY_CONSENT";
export const PREVIEW_MY_CONSENT_REJECTED = NAME + "/PREVIEW_MY_CONSENT_REJECTED";
export const PREVIEW_MY_CONSENT_FULFILLED = NAME + "/PREVIEW_MY_CONSENT_FULFILLED";

// comments
export const GET_COMMENTS = NAME + "/GET_COMMENTS";
export const GET_COMMENTS_FULFILLED = NAME + "/GET_COMMENTS_FULFILLED";
export const GET_COMMENTS_REJECTED = NAME + "/GET_COMMENTS_REJECTED";

export const CREATE_COMMENT = NAME + "/CREATE_COMMENT";
export const CREATE_COMMENT_FULFILLED = NAME + "/CREATE_COMMENT_FULFILLED";
export const CREATE_COMMENT_REJECTED = NAME + "/CREATE_COMMENT_REJECTED";

export const UPDATE_COMMENT = NAME + "/UPDATE_COMMENT";
export const UPDATE_COMMENT_FULFILLED = NAME + "/UPDATE_COMMENT_FULFILLED";
export const UPDATE_COMMENT_REJECTED = NAME + "/UPDATE_COMMENT_REJECTED";

export const DELETE_COMMENT = NAME + "/DELETE_COMMENT";
export const DELETE_COMMENT_FULFILLED = NAME + "/DELETE_COMMENT_FULFILLED";
export const DELETE_COMMENT_REJECTED = NAME + "/DELETE_COMMENT_REJECTED";

export const RESET_COMMENTS = NAME + "/RESET_COMMENTS";

// files
export const GET_FILES = NAME + "/GET_FILES";
export const GET_FILES_FULFILLED = NAME + "/GET_FILES_FULFILLED";
export const GET_FILES_REJECTED = NAME + "/GET_FILES_REJECTED";

export const CREATE_FILE = NAME + "/CREATE_FILE";
export const CREATE_FILE_FULFILLED = NAME + "/CREATE_FILE_FULFILLED";
export const CREATE_FILE_REJECTED = NAME + "/CREATE_FILE_REJECTED";

export const DELETE_FILE = NAME + "/DELETE_FILE";
export const DELETE_FILE_FULFILLED = NAME + "/DELETE_FILE_FULFILLED";
export const DELETE_FILE_REJECTED = NAME + "/DELETE_FILE_REJECTED";

export const RESET_FILES = NAME + "/RESET_FILES";