import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsLoadingPrivacyData,
  getPeople,
  getGroups,
  getTasks,
  getIsLoadingGridData,
  getIsLoadingFeed,
  getComments,
  getIsLoadingComments,
} from "@icarius-pages/feedback/selectors";
import { getFeedbackAction, getFeedbackPrivacyOptionsAction } from "@icarius-pages/feedback/actions";

const useFeedback = () => {

  const [selectedFeedbackPublication, setSelectedFeedbackPublication] = useState(false);

  const dispatch = useDispatch();
  const people = useSelector(getPeople);
  const groups = useSelector(getGroups);
  const tasks = useSelector(getTasks);
  const comments = useSelector(getComments);

  useEffect(() => {
    dispatch(getFeedbackAction(false))
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(getFeedbackPrivacyOptionsAction());
        }
      });
  }, [dispatch])

  const isLoadingPrivacyData = useSelector(getIsLoadingPrivacyData);
  const isLoadingGridData = useSelector(getIsLoadingGridData);
  const isLoadingFeed = useSelector(getIsLoadingFeed);
  const isLoadingComments = useSelector(getIsLoadingComments);

  const isLoadingFeedback = {
    gridData: isLoadingGridData,
    privacyData: isLoadingPrivacyData,
    feed: isLoadingFeed,
    comments: isLoadingComments,
  }

  const handleOpenFeedbackPublication = (feedbackPublication) => {
    setSelectedFeedbackPublication(feedbackPublication);
  }

  const handleCloseFeedbackPublication = () => setSelectedFeedbackPublication(null);

  const state = {
    selectedFeedbackPublication,
  }

  const handlers = {
    handleOpenFeedbackPublication,
    handleCloseFeedbackPublication,
  }

  return {
    state,
    handlers,
    people,
    groups,
    tasks,
    comments,
    isLoadingFeedback,
  };
}

export default useFeedback;