import NAME from "./constants";

export const GET_EMPLOYEE = NAME + "/GET_EMPLOYEE";
export const GET_EMPLOYEE_FULFILLED = NAME + "/GET_EMPLOYEE_FULFILLED";
export const GET_EMPLOYEE_REJECTED = NAME + "/GET_EMPLOYEE_REJECTED";

export const LIKE_REQUEST = NAME + "/LIKE_REQUEST";
export const LIKE_REQUEST_END = NAME + "/LIKE_REQUEST_END";

export const GET_MY_REQUESTS = NAME + "/GET_MY_REQUESTS";
export const GET_MY_REQUESTS_FULFILLED = NAME + "/GET_MY_REQUESTS_FULFILLED";
export const GET_MY_REQUESTS_REJECTED = NAME + "/GET_MY_REQUESTS_REJECTED";

export const MODIFY_REQUEST_STATUS = NAME + "/MODIFY_REQUEST_STATUS";
export const MODIFY_REQUEST_STATUS_FULFILLED = NAME + "/MODIFY_REQUEST_STATUS_FULFILLED";
export const MODIFY_REQUEST_STATUS_REJECTED = NAME + "/MODIFY_REQUEST_STATUS_REJECTED";

export const GET_LICENCE_PERIODS_REJECTED = NAME + "/GET_LICENCE_PERIODS_REJECTED";
export const GET_LICENCE_PERIODS = NAME + "/GET_LICENCE_PERIODS";
export const GET_LICENCE_PERIODS_FULFILLED = NAME + "/GET_LICENCE_PERIODS_FULFILLED";