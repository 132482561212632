import NAME from "./constants";

export const loading = store =>
  store[NAME].gettingMyRequests || store[NAME].modifyingrequest || store[NAME].gettingLicencePeriods;

export const getMyRequests = store => store[NAME].myRequests;
export const getTypesPermission = store => store[NAME].typesPermission;
export const getTypesAdvances = store => store[NAME].typesAdvances;
export const getDecimalSeparator = store => store[NAME].decimalSeparator;
export const getLocale = store => store[NAME].currencyLocalization;
export const getDateFormat = store => store[NAME].dateFormat;
export const getRecipients = store => store[NAME].recipients;
export const getApprovants = store => store[NAME].approvants;
export const getTypes = store => store[NAME].types;
export const getLicencePeriods = store => store[NAME].licencePeriods;
export const getAdditionalLegalDays = store => store[NAME].additionalLegalDays;
export const getAllowHalfDayHolidays = store => store[NAME].allowHalfDayHolidays;