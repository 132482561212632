import NAME from "./constants";

export const GET_MY_PENDING = NAME + "/GET_MY_PENDING";
export const GET_MY_PENDING_FULFILLED = NAME + "/GET_MY_PENDING_FULFILLED";
export const GET_MY_PENDING_REJECTED = NAME + "/GET_MY_PENDING_REJECTED";

export const MODIFY_REQUEST_STATUS = NAME + "/MODIFY_REQUEST_STATUS";
export const MODIFY_REQUEST_STATUS_FULFILLED = NAME + "/MODIFY_REQUEST_STATUS_FULFILLED";
export const MODIFY_REQUEST_STATUS_REJECTED = NAME + "/MODIFY_REQUEST_STATUS_REJECTED";

export const DERIVATE_REQUEST = NAME + "/DERIVATE_REQUEST";
export const DERIVATE_REQUEST_FULFILLED = NAME + "/DERIVATE_REQUEST_FULFILLED";
export const DERIVATE_REQUEST_REJECTED = NAME + "/DERIVATE_REQUEST_REJECTED";