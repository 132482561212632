import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Select } from "mui-rff";
import { Form } from "react-final-form";
import { CloseIcon } from "@icarius-icons";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    MenuItem
} from "@material-ui/core";
import { requestsIds } from "@icarius-utils/requestsIds";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";


const ReplyDialog = (props) => {

    const {
        open,
        title,
        type,
        fireCalculationData,
        legalReason,
        handleCloseDialog,
        handleAccept,
    } = props;

    const isFiringType = type === requestsIds.id_type.FR

    let initialValues = {
        comment: "",
    };

    if (isFiringType) initialValues.option = legalReason || "";

    const CustomButtonYes = withStyles({
        root: {
            "&:hover": {
                backgroundColor: "#16520b",
            },
            backgroundColor: "#268a15",
            marginRight: 10,
            color: "white",
        },
    })(Button);

    const CustomButtonNo = withStyles({
        root: {
            "&:hover": {
                backgroundColor: "#871313",
            },
            backgroundColor: "#df1b1b",
            color: "white",
        },
    })(Button);

    const textIsInvalid = comment => {
        return comment && comment.length > 1023;
    };

    const validateAndConfirm = values => {
        if (textIsInvalid(values.comment)) return;
        if (isFiringType && values.accept && values.option === "") return;
        handleConfirm(values);
    };

    const handleConfirm = ({ comment, accept, option }) => {
        handleAccept && handleAccept(comment, accept, option);
    };

    const handleClose = (shouldComponentUpdate) => {
        handleCloseDialog && handleCloseDialog(shouldComponentUpdate);
    };

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            open={open}
            maxWidth={"xs"}
            fullWidth={true}>
            <div className={"dialog-container"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                    <DialogTitleDivider />
                </DialogTitle>
                <CloseIcon className={"dialog-close-icon icon"} onClick={() => handleClose(false)} />
                <DialogContent style={{ paddingTop: 0 }}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Form
                                onSubmit={validateAndConfirm}
                                initialValues={initialValues}
                                render={({ handleSubmit, form, values }) => (
                                    <form onSubmit={handleSubmit} noValidate>
                                        {
                                            isFiringType &&
                                            <Grid container direction="column" justify="center" item style={{ paddingBottom: "15px" }}>
                                                <Select
                                                    required
                                                    label={getLocalizedString("firingLegalType")}
                                                    name={"option"}
                                                    formControlProps={{
                                                        margin: "normal",
                                                    }}>
                                                    {
                                                        fireCalculationData.legal_reasons.map((item, index) => (
                                                            <MenuItem
                                                                className={"whiteText"}
                                                                key={item.code}
                                                                value={item.code}>
                                                                {item.value}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </Grid>
                                        }

                                        <TextField
                                            name={"comment"}
                                            margin={"none"}
                                            onInput={e => {
                                                e.target.value = e.target.value.toString().slice(0, 1024);
                                            }}
                                            multiline={true}
                                            rows={1}
                                            label={getLocalizedString("insertComment")}
                                            rowsMax={3}
                                        />

                                        <Typography
                                            style={{ paddingTop: 5 }}
                                            className={"whiteText"}
                                            variant="caption"
                                            gutterBottom>
                                            {`${getLocalizedString("optionalField")}. ${getLocalizedString("currentChar")} ${values.comment ? values.comment.length : 0}. ${getLocalizedString("maxCharRequests")
                                                }.`}
                                        </Typography>
                                        <DialogActions>
                                            <CustomButtonNo
                                                name="noButton"
                                                variant="contained"
                                                onClick={() => {
                                                    form.change("accept", false);
                                                }}
                                                type="submit">
                                                {getLocalizedString("rejectRequest")}
                                            </CustomButtonNo>
                                            <CustomButtonYes
                                                name="yesButton"
                                                variant="contained"
                                                onClick={() => {
                                                    form.change("accept", true);
                                                }}
                                                type="submit">
                                                {getLocalizedString("approveRequest")}
                                            </CustomButtonYes>
                                        </DialogActions>
                                    </form>
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
}

export default ReplyDialog;
