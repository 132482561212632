import {
  MODIFY_REQUEST_STATUS,
  MODIFY_REQUEST_STATUS_REJECTED,
  MODIFY_REQUEST_STATUS_FULFILLED,
  GET_MY_REQUESTS,
  GET_MY_REQUESTS_FULFILLED,
  GET_MY_REQUESTS_REJECTED,
  GET_LICENCE_PERIODS_REJECTED,
  GET_LICENCE_PERIODS,
  GET_LICENCE_PERIODS_FULFILLED,
} from "./actionTypes";

const initialState = {
  helpDocumentation: "",
  likeRequest: false,
  myRequests: [],
  gettingMyRequests: false,
  modifyingrequest: false,
  gettingLicencePeriods: false,
  typesPermission: [],
  typesAdvances: [],
  decimalSeparator: null,
  currencyLocalization: null,
  dateFormat: null,
  licencePeriods: [],
  types: [],
  recipients: [],
  approvants: [],
  additionalLegalDays: 0,
  allowHalfDayHolidays: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MY_REQUESTS:
      return { ...state, gettingMyRequests: true };
    case GET_MY_REQUESTS_REJECTED:
      return { ...state, gettingMyRequests: false };
    case GET_MY_REQUESTS_FULFILLED:
      return {
        ...state,
        gettingMyRequests: false,
        myRequests: action.payload.myRequests,
        typesPermission: action.payload.typesPermission,
        typesAdvances: action.payload.typesAdvances,
        decimalSeparator: action.payload.decimalSeparator,
        currencyLocalization: action.payload.currencyLocalization,
        dateFormat: action.payload.dateFormat,
        recipients: action.payload.recipients,
        approvants: action.payload.approvants,
        additionalLegalDays: action.payload.additionalLegalDays,
        allowHalfDayHolidays: action.payload.allowHalfDayHolidays,
      };
    case MODIFY_REQUEST_STATUS_FULFILLED:
      return { ...state, modifyingrequest: false };
    case MODIFY_REQUEST_STATUS_REJECTED:
      return { ...state, modifyingrequest: false };
    case MODIFY_REQUEST_STATUS:
      return { ...state, modifyingrequest: true };
    case GET_LICENCE_PERIODS_REJECTED:
      return { ...state, gettingLicencePeriods: false };
    case GET_LICENCE_PERIODS_FULFILLED:
      return { ...state, gettingLicencePeriods: false, types: action.payload.types, licencePeriods: action.payload.licencePeriods };
    case GET_LICENCE_PERIODS:
      return { ...state, gettingLicencePeriods: true };
    default:
      return state;
  }
}
