import * as actionTypes from "./actionTypes";
import {
  getMyOnboardingTrackingAPI,
  getMomentsMyOnboardingTrackingAPI,
  getActionsMyOnboardingTrackingAPI,
  getProgressMyOnboardingTrackingAPI,
  updateMaterialMyOnboardingTrackingAPI,
  previewMyConsentAPI,
  acceptMyConsentAPI,
  getMyOnboardingTrackingActionCommentsAPI,
  createMyOnboardingTrackingActionCommentsAPI,
  updateMyOnboardingTrackingActionCommentsAPI,
  deleteMyOnboardingTrackingActionCommentsAPI,
  getMyOnboardingTrackingActionFilesAPI,
  createMyOnboardingTrackingActionFileAPI,
  deleteMyOnboardingTrackingActionFileAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { previewFromBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "INVALID_CONSENT":
      errorString = "Consentimiento ya aceptado.";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getMyOnboardingTrackingAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getMyOnboardingTrackingAPI();

    let processList = response.data && response.data.processList;
    let employeeList = response.data && response.data.employeeList;
    let actionTypeList = response.data && response.data.actionTypeList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        processList,
        actionTypeList,
        employeeList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const getMomentsMyOnboardingTrackingAction = (processCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_MOMENTS });
  try {
    let response = await getMomentsMyOnboardingTrackingAPI(`?processCode=${processCode}`);

    let momentList = response.data && response.data.momentList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_MOMENTS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_MOMENTS_FULFILLED,
      payload: {
        momentList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_MOMENTS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const getProgressMyOnboardingTrackingAction = (processCode, momentCode = "") => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PROGRESS });
  try {
    let response = await getProgressMyOnboardingTrackingAPI(`?processCode=${processCode}&momentCode=${momentCode}`);

    let momentProgress = response.data && response.data.momentProgress;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_PROGRESS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_PROGRESS_FULFILLED,
      payload: {
        progressData: { momentProgress },
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_PROGRESS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const getActionsMyOnboardingTrackingAction = (processCode, momentCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ACTIONS });
  try {
    let response = await getActionsMyOnboardingTrackingAPI(`?processCode=${processCode}&momentCode=${momentCode}`);

    let actionList = response.data && response.data.actionList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_ACTIONS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_ACTIONS_FULFILLED,
      payload: {
        actionList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ACTIONS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const updateMaterialMyOnboardingTrackingAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_MATERIAL });
  try {
    let response = await updateMaterialMyOnboardingTrackingAPI(dataToSend);

    let actionList = response.data && response.data.actionList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_MATERIAL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_MATERIAL_FULFILLED,
      payload: {
        actionList,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_MATERIAL_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const acceptMyConsentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.ACCEPT_MY_CONSENT });
  try {
    let response = await acceptMyConsentAPI(dataToSend);

    let myConsents = response.data && response.data.myConsents;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.ACCEPT_MY_CONSENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.ACCEPT_MY_CONSENT_FULFILLED,
      payload: { myConsents },
    });
    dispatch(openSnackbarAction({ msg: 'Muchas gracias por aceptar el consentimiento', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ACCEPT_MY_CONSENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const previewMyConsentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.PREVIEW_MY_CONSENT });
  try {
    let response = await previewMyConsentAPI(dataToSend);

    if (response.status !== 200) {
      dispatch({ type: actionTypes.PREVIEW_MY_CONSENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    let title = response.headers["x-content-namefile"];
    previewFromBlob(response.data, getFileExtension(title), getFileName(title));

    dispatch({ type: actionTypes.PREVIEW_MY_CONSENT_FULFILLED });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.PREVIEW_MY_CONSENT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

// comments
export const getMyOnboardingTrackingActionCommentsAction = (actionCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COMMENTS });
  try {
    let response = await getMyOnboardingTrackingActionCommentsAPI(`?actionCode=${actionCode}`);

    let comments = response.data && response.data.comments;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_COMMENTS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_COMMENTS_FULFILLED,
      payload: { comments },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_COMMENTS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createMyOnboardingTrackingActionCommentsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_COMMENT });
  try {
    let response = await createMyOnboardingTrackingActionCommentsAPI({ ...dataToSend, text: JSON.stringify(dataToSend.text) });

    let comments = response.data && response.data.comments;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_COMMENT_FULFILLED,
      payload: { comments },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_COMMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateMyOnboardingTrackingActionCommentsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_COMMENT });
  try {
    let response = await updateMyOnboardingTrackingActionCommentsAPI({ ...dataToSend, text: JSON.stringify(dataToSend.text) });

    let comments = response.data && response.data.comments;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_COMMENT_FULFILLED,
      payload: { comments },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_COMMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteMyOnboardingTrackingActionCommentsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_COMMENT });
  try {
    let response = await deleteMyOnboardingTrackingActionCommentsAPI(dataToSend);

    let comments = response.data && response.data.comments;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_COMMENT_FULFILLED,
      payload: { comments },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_COMMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

// files
export const getMyOnboardingTrackingActionFilesAction = (actionCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_FILES });
  try {
    let response = await getMyOnboardingTrackingActionFilesAPI(`?actionCode=${actionCode}`);

    let files = response.data && response.data.files;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_FILES_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FILES_FULFILLED,
      payload: { files },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_FILES_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createMyOnboardingTrackingActionFileAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_FILE });
  try {
    let formData = new FormData();
    Object.keys(dataToSend).forEach((key) => {
      formData.append(key, dataToSend[key]);
    });

    let response = await createMyOnboardingTrackingActionFileAPI(formData);

    let files = response.data && response.data.files;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_FILE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FILE_FULFILLED,
      payload: { files },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_FILE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteMyOnboardingTrackingActionFileAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_FILE });
  try {
    let response = await deleteMyOnboardingTrackingActionFileAPI(dataToSend);

    let files = response.data && response.data.files;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_FILE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FILE_FULFILLED,
      payload: { files },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_FILE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};