import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  isLoadingProgress: false,
  isLoadingComments: false,
  isLoadingFiles: false,
  processList: [],
  progressData: null,
  actionTypeList: [],
  employeeList: [],
  momentList: [],
  actionList: [],
  comments: [],
  files: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        processList: action.payload.processList,
        actionTypeList: action.payload.actionTypeList,
        employeeList: action.payload.employeeList,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_PROGRESS:
      return { ...state, isLoadingProgress: true };
    case actionTypes.GET_PROGRESS_FULFILLED:
      return {
        ...state,
        isLoadingProgress: false,
        progressData: action.payload.progressData,
      };
    case actionTypes.GET_PROGRESS_REJECTED:
      return { ...state, isLoadingProgress: false };

    case actionTypes.GET_MOMENTS:
      return { ...state, isLoading: true };
    case actionTypes.GET_MOMENTS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        momentList: action.payload.momentList,
      };
    case actionTypes.GET_MOMENTS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_ACTIONS:
      return { ...state, isLoading: true };
    case actionTypes.GET_ACTIONS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        actionList: action.payload.actionList,
      };
    case actionTypes.GET_ACTIONS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPDATE_MATERIAL:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_MATERIAL_FULFILLED:
      return {
        ...state,
        isLoading: false,
        actionList: action.payload.actionList,
      };
    case actionTypes.UPDATE_MATERIAL_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.ACCEPT_MY_CONSENT:
      return { ...state, isLoading: true };
    case actionTypes.ACCEPT_MY_CONSENT_FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.ACCEPT_MY_CONSENT_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.PREVIEW_MY_CONSENT:
      return { ...state, isLoading: true };
    case actionTypes.PREVIEW_MY_CONSENT_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.PREVIEW_MY_CONSENT_REJECTED:
      return { ...state, isLoading: false };

    // comments //////////////////////////////////////////////////////////////////////
    case actionTypes.GET_COMMENTS:
      return { ...state, isLoadingComments: true };
    case actionTypes.GET_COMMENTS_FULFILLED:
      return {
        ...state,
        isLoadingComments: false,
        comments: action.payload.comments,
      };
    case actionTypes.GET_COMMENTS_REJECTED:
      return { ...state, isLoadingComments: false };

    case actionTypes.CREATE_COMMENT:
      return { ...state, isLoadingComments: true };
    case actionTypes.CREATE_COMMENT_FULFILLED:
      return {
        ...state,
        isLoadingComments: false,
        comments: action.payload.comments,
      };
    case actionTypes.CREATE_COMMENT_REJECTED:
      return { ...state, isLoadingComments: false };

    case actionTypes.UPDATE_COMMENT:
      return { ...state, isLoadingComments: true };
    case actionTypes.UPDATE_COMMENT_FULFILLED:
      return {
        ...state,
        isLoadingComments: false,
        comments: action.payload.comments,
      };
    case actionTypes.UPDATE_COMMENT_REJECTED:
      return { ...state, isLoadingComments: false };

    case actionTypes.DELETE_COMMENT:
      return { ...state, isLoadingComments: true };
    case actionTypes.DELETE_COMMENT_FULFILLED:
      return {
        ...state,
        isLoadingComments: false,
        comments: action.payload.comments,
      };
    case actionTypes.DELETE_COMMENT_REJECTED:
      return { ...state, isLoadingComments: false, };

    case actionTypes.RESET_COMMENTS:
      return { ...state, comments: [], };

    // files //////////////////////////////////////////////////////////////////////
    case actionTypes.GET_FILES:
      return { ...state, isLoadingFiles: true };
    case actionTypes.GET_FILES_FULFILLED:
      return {
        ...state,
        isLoadingFiles: false,
        files: action.payload.files,
      };
    case actionTypes.GET_FILES_REJECTED:
      return { ...state, isLoadingFiles: false };

    case actionTypes.CREATE_FILE:
      return { ...state, isLoadingFiles: true };
    case actionTypes.CREATE_FILE_FULFILLED:
      return {
        ...state,
        isLoadingFiles: false,
        files: action.payload.files,
      };
    case actionTypes.CREATE_FILE_REJECTED:
      return { ...state, isLoadingFiles: false };


    case actionTypes.DELETE_FILE:
      return { ...state, isLoadingFiles: true };
    case actionTypes.DELETE_FILE_FULFILLED:
      return {
        ...state,
        isLoadingFiles: false,
        files: action.payload.files,
      };
    case actionTypes.DELETE_FILE_REJECTED:
      return { ...state, isLoadingFiles: false, };

    case actionTypes.RESET_FILES:
      return { ...state, files: [] };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
