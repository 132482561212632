import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading || store[NAME].isLoadingFiles || store[NAME].isLoadingComments;
export const getProcessList = store => store[NAME].processList;
export const getActionTypeList = store => store[NAME].actionTypeList;
export const getMomentList = store => store[NAME].momentList;
export const getActionList = store => store[NAME].actionList;
export const getEmployeeList = store => store[NAME].employeeList;
export const getProgressData = store => store[NAME].progressData;
export const getComments = store => store[NAME].comments;
export const getFiles = store => store[NAME].files;