import {
  MODIFY_REQUEST_STATUS,
  MODIFY_REQUEST_STATUS_REJECTED,
  MODIFY_REQUEST_STATUS_FULFILLED,
  GET_MY_PENDING,
  GET_MY_PENDING_FULFILLED,
  GET_MY_PENDING_REJECTED,  
  DERIVATE_REQUEST,
  DERIVATE_REQUEST_FULFILLED,
  DERIVATE_REQUEST_REJECTED
} from "./actionTypes";

const initialState = {
  typesAdvances: [],
  requestRows: [],
  employers: [],
  recipients: [],
  approvants: [],
  fireCalculationData: {},
  raiseCalculationData: {},
  advanceCalculationData: {},
  
  gettingMyPending: false,
  modifyingRequest: false,  
  derivatingRequest: false,

  decimalSeparator: null,
  currencyLocalization: null,
  dateFormat: null,  
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MY_PENDING:
      return { ...state, gettingMyPending: true };
    case GET_MY_PENDING_REJECTED:
      return { ...state, gettingMyPending: false };
    case GET_MY_PENDING_FULFILLED:
      return {
        ...state,
        gettingMyPending: false,
        requestRows: action.payload.requestRows,        
        employers: action.payload.employers,
        fireCalculationData: action.payload.fireCalculationData,
        raiseCalculationData: action.payload.raiseCalculationData,
        advanceCalculationData: action.payload.advanceCalculationData,
        typesAdvances: action.payload.typesAdvances,

        decimalSeparator: action.payload.decimalSeparator,
        currencyLocalization: action.payload.currencyLocalization,
        dateFormat: action.payload.dateFormat,
        recipients: action.payload.recipients,
        approvants: action.payload.approvants,
      };
    case MODIFY_REQUEST_STATUS_FULFILLED:
      return { ...state, modifyingRequest: false };
    case MODIFY_REQUEST_STATUS_REJECTED:
      return { ...state, modifyingRequest: false };
    case MODIFY_REQUEST_STATUS:
      return { ...state, modifyingRequest: true };    
    case DERIVATE_REQUEST:
        return { ...state, derivatingRequest: true };
    case DERIVATE_REQUEST_REJECTED:
      return { ...state, derivatingRequest: false };
    case DERIVATE_REQUEST_FULFILLED:
      return { ...state, derivatingRequest: false };    
    default:
      return state;
  }
}
