import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const SelectApprovantDialog = (props) => {

  const { open, handleAccept, handleClose, selectionData } = props;

  const [selectedData, setSelectedData] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!selectedData) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al próximo firmante", severity: 'error' }));
      return;
    }

    handleAccept(selectedData);
  };

  return (
    <Dialog
      open={open}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}>
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {'Atención'}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <div className={"center-input"}>
            <Typography className="whiteText">
              {'Este tipo de documento requiere múltiples aprobadores o firmantes.'}
            </Typography>
            <Typography className="whiteText" style={{ marginTop: 20 }}>
              {'Seleccione quien será el siguiente firmante que también deberá aprobar este documento luego que sea firmado por usted.'}
            </Typography>
            <FormControl style={{ width: "100%", marginTop: 20 }}>
              <InputLabel required id={`label`}>{"Siguiente firmante"}</InputLabel>
              <Select
                value={selectedData}
                labelId={`label`}
                id={`select`}
                onChange={(e) => setSelectedData(e.target.value)}
                margin={"none"}
              >
                {
                  selectionData.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("agree")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default SelectApprovantDialog;
