import { getLocalizedString } from "@icarius-localization/strings";
import React from "react";
import { Select } from "mui-rff";
import { Form } from "react-final-form";
import { CloseIcon } from "@icarius-icons";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    MenuItem
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";


const DerivateDialog = ({ open, options, handleCloseDialog, handleDerivate }) => {

    const initialValues = {
        option: "",
    };

    const validateAndConfirm = values => {
        if (values.option === "") return;
        handleConfirm(values);
    };

    const handleConfirm = (values) => {
        handleDerivate && handleDerivate(values.option);
    };

    const handleClose = (shouldComponentUpdate) => {
        handleCloseDialog && handleCloseDialog(shouldComponentUpdate);
    };

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            open={open}
            maxWidth={"xs"}
            fullWidth={true}>
            <div className={"dialog-container"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {getLocalizedString("derivateRequestTitle")}
                    <DialogTitleDivider />
                </DialogTitle>
                <CloseIcon className={"dialog-close-icon icon"} onClick={() => handleClose(false)} />
                <DialogContent style={{ paddingTop: 0 }}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Form
                                onSubmit={validateAndConfirm}
                                initialValues={initialValues}
                                render={({ handleSubmit, form, values }) => (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <Grid container direction="row" justify="center" item>
                                            <Select
                                                label={getLocalizedString("addressee")}
                                                name={"option"}
                                                formControlProps={{
                                                    margin: "normal",
                                                }}>
                                                {
                                                    options.map((item, index) => (
                                                        <MenuItem
                                                            className={"whiteText"}
                                                            key={item.code}
                                                            value={item.code}>
                                                            {item.value}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </Grid>

                                        <DialogActions>
                                            <ButtonDialogAction
                                                onClick={() => handleClose(false)}
                                                text={getLocalizedString("disagree")}
                                            />
                                            <ButtonDialogAction
                                                type="submit"
                                                isAccept
                                                text={getLocalizedString("derivate")}
                                            />
                                        </DialogActions>
                                    </form>
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
}

export default DerivateDialog;
