import React, { useState, useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { getMyPendingRows, changeRequestStatus, derivateRequest } from "../actions";
import {
  loading,
  getMyPending,
  getFireCalculationData,
  getRaiseCalculationData,
  getAdvanceCalculationData,
  getEmployers,
  getDecimalSeparator,
  getLocale,
  getDateFormat,
  getTypesAdvances,
  getRecipients,
  getApprovants,
} from "../selectors";
import { getAppColor } from "src/app/selectors";
import { getIsExportingGrid } from "src/app/selectors";
import ReplyDialog from "./dialogs/replyDialog";
import DerivateDialog from "./dialogs/derivateDialog";
import SecondGridDialog from "./dialogs/secondGridDialog";
import RequestDialog from "@icarius-pages/myRequestsManager/components/modalRequests/RequestDialog";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { requestsIds } from "@icarius-utils/requestsIds";
import SelectApprovantDialog from "@icarius-pages/myPending/components/dialogs/selectApprovantDialog";

const MyPending = (props) => {

  const [includesHistory, setIncludesHistory] = useState(false);
  const [currentRequestWorkflow, setCurrentRequestWorkflow] = useState([]);
  const [requestType, setRequestType] = useState(null);
  const [canAnswerAppeal, setCanAnswerAppeal] = useState(null);
  const [canModifyAppeal, setCanModifyAppeal] = useState(null);
  const [secondGridDialogIsOpen, setSecondGridDialogIsOpen] = useState(false);
  const [replyDialogIsOpen, setReplyDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [derivateDialogIsOpen, setDerivateDialogIsOpen] = useState(false);
  const [shouldGetDataAgain, setShouldGetDataAgain] = useState(false);
  const [selectApprovantDialogIsOpen, setSelectApprovantDialogIsOpen] = useState(false);
  const [approveResponseData, setApproveResponseData] = useState(null);

  const {
    myPendingRows,
    fireCalculationData,
    raiseCalculationData,
    advanceCalculationData,
    employers,
    loading,
    exportingGrid,
    color,
    dateFormat,
    locale,
    dispatch,
    typesAdvances,
    recipients,
    approvants,
  } = props;

  useEffect(() => { //traer los datos la primera vez y cada vez que cambie includesHistory
    dispatch(getMyPendingRows(includesHistory));
  }, [dispatch, includesHistory])

  useEffect(() => { //traer datos de nuevo cuando shouldGetDataAgain es true (se setea en true cuando se cierran los modales despues de pegarle a sus apis)
    if (!shouldGetDataAgain) return;
    dispatch(getMyPendingRows(includesHistory));
    setShouldGetDataAgain(false);
  }, [shouldGetDataAgain, includesHistory, dispatch])

  const handleRequest = (data) => {

    data.id_status = "3";

    dispatch(changeRequestStatus(data, requestType))
      .then(resp => handleCloseEditDialog(resp))
      .then(() => handleCloseSecondGridDialog())
  }

  const handleAccept = (comment, approves, legalReason) => {
    let data = {
      "code": currentRequestWorkflow[0].code,
      "id_status": approves ? "6" : "7",
      "comments": comment,
    }

    if (requestType === requestsIds.id_type.FR) {
      data.legal_reason_id = legalReason;
    }

    //Si rechaza la propuesta, pasa al estado 7
    if (!approves) {
      changeRequestStatusAndClose(data);
    } else {
      // si aprueba, pasa al estado 6, pero antes verificar si tiene aprobacion multiple
      if (currentRequestWorkflow[0].multipleApprovation) {
        // si es de multiple aprobación, abrir el modal
        setApproveResponseData(data);
        setSelectApprovantDialogIsOpen(true);
      } else {
        // sino, aprobar de una
        changeRequestStatusAndClose(data);
      }
    }
  }

  const handleCloseSelectApprovantDialog = () => {
    setApproveResponseData(null);
    setSelectApprovantDialogIsOpen(false);
  }

  const changeRequestStatusAndClose = (data) => {
    handleCloseSelectApprovantDialog();
    dispatch(changeRequestStatus(data, requestType))
      .then(resp => handleCloseReplyDialog(resp))
      .then(() => handleCloseSecondGridDialog());
  }

  const acceptWithMultipleApprovation = (approvant) => {
    const dataToUse = { ...approveResponseData };
    dataToUse["extra_approvant"] = approvant;
    changeRequestStatusAndClose(dataToUse);
  }

  const handleCloseReplyDialog = (shouldReloadTable) => {
    setReplyDialogIsOpen(false);
    if (typeof shouldReloadTable === "object" && shouldReloadTable.data.status === "OK") {
      setShouldGetDataAgain(true);
    }
  }

  const handleCloseEditDialog = (shouldReloadTable) => {
    setEditDialogIsOpen(false);
    if (typeof shouldReloadTable === "object" && shouldReloadTable.data.status === "OK") {
      setShouldGetDataAgain(true);
      setRequestType(null);
      setCurrentRequestWorkflow([]);
    }
  }

  const handleCloseDerivateDialog = (shouldReloadTable) => {
    setDerivateDialogIsOpen(false);
    if (typeof shouldReloadTable === "object" && shouldReloadTable.data.status === "OK") {
      setShouldGetDataAgain(true);
      setRequestType(null);
      setCurrentRequestWorkflow([]);
    }
  }

  const handleDerivate = (employer_id) => {
    const data = {
      code: currentRequestWorkflow[0].code,
      employer_id,
    }

    dispatch(derivateRequest(data))
      .then(resp => handleCloseDerivateDialog(resp))
      .then(() => handleCloseSecondGridDialog())
  }

  const handleRowClick = row => {
    setRequestType(row.data.type);
    setCurrentRequestWorkflow(row.data.complete_workflow);
    setCanAnswerAppeal(row.data.canAnswerAppeal);
    setCanModifyAppeal(row.data.canModifyAppeal);
    setSecondGridDialogIsOpen(true);
  };

  const handleCloseSecondGridDialog = () => {
    setCanAnswerAppeal(null);
    setCanModifyAppeal(null);
    setSecondGridDialogIsOpen(false);
  }

  return (
    <CommonPage
      rowData={myPendingRows}
      title={getLocalizedString("myPending")}
      gridTitle={getLocalizedString("myPending")}
      locale={locale}
      dateFormat={dateFormat}
      columnDefPage={paths.myPendingEmployer}
      handleRowClick={handleRowClick}
      isLoading={loading || exportingGrid}
      hasHelp
      hasExpand
      hasSelectAll
      hasSwitch
      switchValue={includesHistory}
      handleSwitchClick={() => setIncludesHistory(prev => !prev)}
      switchLabelOn={getLocalizedString("requestsInHistory")}
      switchLabelOff={getLocalizedString("requestsInProgress")}
    >
      {
        secondGridDialogIsOpen && !loading && !exportingGrid &&
        <SecondGridDialog
          open={secondGridDialogIsOpen}
          data={currentRequestWorkflow}
          type={requestType}
          canAnswerAppeal={canAnswerAppeal}
          canModifyAppeal={canModifyAppeal}
          dateFormat={dateFormat}
          locale={locale}
          handleOpenDerivate={() => setDerivateDialogIsOpen(true)}
          handleOpenEditDialog={() => setEditDialogIsOpen(true)}
          handleOpenReplyDialog={() => setReplyDialogIsOpen(true)}
          handleOpenViewDialog={() => { setIsReadOnly(true); setEditDialogIsOpen(true) }}
          handleClose={handleCloseSecondGridDialog}
        />
      }
      {
        editDialogIsOpen && !loading && !exportingGrid &&
        <RequestDialog
          employers={employers}
          fireCalculationData={fireCalculationData}
          raiseCalculationData={raiseCalculationData}
          advanceCalculationData={advanceCalculationData}
          open={editDialogIsOpen}
          requestType={requestType}
          handleClose={handleCloseEditDialog}
          editData={currentRequestWorkflow[0]}
          isForEdit={true}
          requestCode={currentRequestWorkflow[0].code}
          handleRequest={handleRequest}
          companyColor={color}
          isReadOnly={isReadOnly}
          typesAdvances={typesAdvances}
          handleCloseViewDialog={() => { setIsReadOnly(false); setEditDialogIsOpen(false) }}
          recipients={recipients}
        />
      }
      {
        replyDialogIsOpen && !loading && !exportingGrid &&
        <ReplyDialog
          open={replyDialogIsOpen}
          handleCloseDialog={handleCloseReplyDialog}
          handleAccept={handleAccept}
          title={getLocalizedString("acceptRequest")}
          type={requestType}
          fireCalculationData={fireCalculationData}
          legalReason={currentRequestWorkflow[0].legal_reason_id}
        />
      }
      {
        derivateDialogIsOpen && !loading && !exportingGrid &&
        <DerivateDialog
          open={derivateDialogIsOpen}
          options={employers}
          handleCloseDialog={handleCloseDerivateDialog}
          handleDerivate={handleDerivate}
        />
      }
      {
        selectApprovantDialogIsOpen &&
        <SelectApprovantDialog
          open={selectApprovantDialogIsOpen}
          handleAccept={acceptWithMultipleApprovation}
          handleClose={handleCloseSelectApprovantDialog}
          selectionData={approvants}
        />
      }
    </CommonPage>
  );
}

export default connect(
  createStructuredSelector({
    loading: loading,

    typesAdvances: getTypesAdvances,
    myPendingRows: getMyPending,
    fireCalculationData: getFireCalculationData,
    raiseCalculationData: getRaiseCalculationData,
    advanceCalculationData: getAdvanceCalculationData,
    employers: getEmployers,

    dateFormat: getDateFormat,
    locale: getLocale,
    decimalSeparator: getDecimalSeparator,

    exportingGrid: getIsExportingGrid,

    color: getAppColor,
    recipients: getRecipients,
    approvants: getApprovants,
  })
)(MyPending);
