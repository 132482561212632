import React from "react";
import { DialogTitle, Dialog, DialogContent, Button, Grid } from "@material-ui/core";
import { CloseIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import paths from "@icarius-localization/paths";
import { requestsIds } from "@icarius-utils/requestsIds";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import CommonPage from "@icarius-common/commonPage";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
    minHeight: "85%",
    maxWidth: "95%",
    minWidth: "95%",
  },
})(Dialog);

const SecondGridDialog = (props) => {

  const {
    open,
    data,
    type,
    canModifyAppeal,
    canAnswerAppeal,
    dateFormat,
    locale,
    handleOpenDerivate,
    handleOpenEditDialog,
    handleOpenReplyDialog,
    handleOpenViewDialog,
    handleClose,
  } = props;

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {`${getLocalizedString("requestHistory")} - ${data[0].code}`}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container item xs={12} justify="flex-end" alignItems="center" style={{ paddingBottom: 10 }}>
          <Button style={{ marginBottom: 5 }} className="whiteText" disableRipple={true} variant={"outlined"} onClick={handleOpenViewDialog}>
            {getLocalizedString("viewButton")}
          </Button>
          {
            type !== requestsIds.id_type.ANT && data[0].id_status === "1" &&
            <Button style={{ marginLeft: 7, marginBottom: 5 }} className="whiteText" disableRipple={true} variant={"outlined"} onClick={handleOpenDerivate}>
              {getLocalizedString("derivate")}
            </Button>
          }
          {
            canModifyAppeal &&
            <Button style={{ marginLeft: 7, marginBottom: 5 }} className="whiteText" disableRipple={true} variant={"outlined"} onClick={handleOpenEditDialog}>
              {getLocalizedString("proposeChange")}
            </Button>
          }
          {
            canAnswerAppeal &&
            <Button style={{ marginLeft: 7, marginBottom: 5 }} className="whiteText" disableRipple={true} variant={"outlined"} onClick={handleOpenReplyDialog}>
              {getLocalizedString("reply")}
            </Button>
          }
        </Grid>
        <CommonPage
          gridTitle={`${getLocalizedString("requestHistory")} - ${data[0].code}`}
          columnDefPage={paths.myPendingSecondGrid}
          rowData={data}
          locale={locale}
          dateFormat={dateFormat}
          gridHeight={'70vh'}
          ignoreProcessesbyPath
        />
      </DialogContent>
    </StyledDialog>
  );
}

export default SecondGridDialog;
