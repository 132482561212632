import {
  GET_MY_REQUESTS,
  GET_MY_REQUESTS_FULFILLED,
  GET_MY_REQUESTS_REJECTED,
  MODIFY_REQUEST_STATUS_REJECTED,
  MODIFY_REQUEST_STATUS_FULFILLED,
  MODIFY_REQUEST_STATUS,
  GET_LICENCE_PERIODS_REJECTED,
  GET_LICENCE_PERIODS,
  GET_LICENCE_PERIODS_FULFILLED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getManagerPendingRequestsAPI, modifyVacationAppealStatusAPI, modifyAdvanceAppealStatusAPI, modifyPermissionAppealStatusAPI, getLicensePeriods } from "@icarius-connection/api";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { requestsIds, getRequestSnackbarText } from "@icarius-utils/requestsIds";

export const getMyRequestsRows = (includesHistory) => async (dispatch) => {
  dispatch({ type: GET_MY_REQUESTS });
  try {
    let response = await getManagerPendingRequestsAPI(`?include_historicals=${includesHistory}`);

    if (response.data.result !== false) {
      let myRequests = response.data.result;
      let typesPermission = response.data.types_permissions;
      let typesAdvances = response.data.types_advances;
      let decimalSeparator = response.data.decimal_separator;
      let currencyLocalization = response.data.currency_localization;
      let dateFormat = response.data.date_format;
      let recipients = response.data.recipients;
      let approvants = response.data.approvants;
      let additionalLegalDays = response.data.additional_legal_days;
      let allowHalfDayHolidays = response.data.allowHalfDayHolidays;

      dispatch({
        type: GET_MY_REQUESTS_FULFILLED,
        payload: {
          myRequests: myRequests,
          typesPermission: typesPermission,
          typesAdvances: typesAdvances,
          decimalSeparator: decimalSeparator,
          currencyLocalization: currencyLocalization,
          dateFormat: dateFormat,
          recipients: recipients,
          approvants: approvants,
          additionalLegalDays: additionalLegalDays,
          allowHalfDayHolidays: allowHalfDayHolidays,
        },
      });
    } else {

      dispatch({
        type: GET_MY_REQUESTS_FULFILLED,
        payload: {
          myRequests: [],
        },
      });
      return;
    }

    return response;
  } catch (e) {
    dispatch({ type: GET_MY_REQUESTS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const changeRequestStatus = (data, type) => async (dispatch) => {
  dispatch({ type: MODIFY_REQUEST_STATUS });
  try {
    let response;

    switch (type) {
      case requestsIds.id_type.VAC:
        response = await modifyVacationAppealStatusAPI(data);
        break;
      case requestsIds.id_type.PERM:
        response = await modifyPermissionAppealStatusAPI(data);
        break;
      case requestsIds.id_type.ANT:
        response = await modifyAdvanceAppealStatusAPI(data);
        break;
      default:
        dispatch({ type: MODIFY_REQUEST_STATUS_REJECTED });
        return;
    }

    if (response.data.status === "OK") {
      dispatch(openSnackbarAction({ msg: getRequestSnackbarText(data.id_status), severity: "success" }));
      dispatch({
        type: MODIFY_REQUEST_STATUS_FULFILLED,
      });
    } else {
      let msg;
      let duration = 5000;
      switch (response.data.status) {
        case "ERROR_NO_DATE_AVAILABLE":
          msg = response.data.message;
          duration = null;
          break;
        case "ERROR_MAX_TIME_PER_DAY":
          msg = getLocalizedString("permissionTimeNotGreater");
          break;
        case "VALIDATION_ERROR":
          msg = getLocalizedErrorString("validationErrorRequests");
          break;
        case "ERROR_DAYS_QUANTITY":
          msg = getLocalizedErrorString("errorDaysQuantityRequests");
          break;
        case "ERROR_FORMAT_DATES":
          msg = getLocalizedErrorString("formatDateErrorRequests");
          break;
        case "ERROR_DATES":
          msg = getLocalizedErrorString("dateErrorRequests");
          break;
        case "ERROR_OVERLAP":
          msg = getLocalizedErrorString("overlapErrorRequests");
          break;
        default:
          msg = getLocalizedErrorString("defaultException");
      }
      dispatch({ type: MODIFY_REQUEST_STATUS_REJECTED });
      dispatch(openSnackbarAction({ msg: msg, severity: "error", duration }));
      return;
    }
    return response;
  } catch (e) {
    dispatch({ type: MODIFY_REQUEST_STATUS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};


export const getLicensePeriodsAction = (startDate, endDate) => async (dispatch) => {
  dispatch({ type: GET_LICENCE_PERIODS });
  try {

    let response = await getLicensePeriods(`?desde=${startDate}&hasta=${endDate}`);
    let status = response.data.status;

    if (status !== "OK") {
      dispatch({ type: GET_LICENCE_PERIODS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    let licencePeriods = response.data.licencePeriods;
    let types = response.data.types;

    dispatch({
      type: GET_LICENCE_PERIODS_FULFILLED,
      payload: {
        licencePeriods: licencePeriods,
        types: types,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: GET_LICENCE_PERIODS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};