import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import { CloseIcon } from "@icarius-icons";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";


const CustomButtonYes = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "#16520b",
        },
        backgroundColor: "#268a15",
        marginRight: 10,
        color: "white",
    },
})(Button);

const CustomButtonNo = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "#871313",
        },
        backgroundColor: "#df1b1b",
        color: "white",
    },
})(Button);

const ReplyDialog = (props) => {

    const {
        open,
        handleCloseDialog,
        handleAccept,
        title,
        editData,
        vacationCalculationData,
    } = props;

    const textIsInvalid = (values) => {
        return values.comment && values.comment.length > 1023;
    };

    const validateAndConfirm = (values) => {
        if (textIsInvalid(values)) return;
        handleConfirm(values);
    };

    const handleConfirm = (values) => {
        handleAccept(values.comment, values.accept);
    };

    const handleClose = (shouldComponentUpdate) => {
        handleCloseDialog(shouldComponentUpdate);
    };

    const initialValues = {
        comment: "",
    };

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            open={open}
            maxWidth={"sm"}
            fullWidth={true}>
            <div className={"dialog-container"}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {title}
                    <DialogTitleDivider />
                </DialogTitle>
                <CloseIcon className={"dialog-close-icon icon"} onClick={() => handleClose(false)} />
                <DialogContent>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Form
                                onSubmit={validateAndConfirm}
                                initialValues={initialValues}
                                render={({ handleSubmit, form, submitting, pristine, values, active }) => (
                                    <form onSubmit={handleSubmit} noValidate>

                                        {editData && vacationCalculationData &&
                                            editData.id_type === "VAC" && editData.id_subtype === "UV" &&
                                            editData.duration_days > vacationCalculationData.available_legal_vacation_days &&
                                            <Grid container item style={{ paddingBottom: 15 }}>
                                                <Typography
                                                    style={{ paddingTop: 5, color: 'red', fontWeight: 700 }}
                                                    variant="caption"
                                                    gutterBottom>
                                                    {
                                                        editData.duration_days === 1 ?
                                                            `El día solicitado supera el saldo disponible en la cuenta corriente en ${editData.duration_days - vacationCalculationData.available_legal_vacation_days} día${(editData.duration_days - vacationCalculationData.available_legal_vacation_days) > 1 ? "s" : ""}` :
                                                            `Los ${editData.duration_days} días solicitados superan el saldo disponible en la cuenta corriente en ${editData.duration_days - vacationCalculationData.available_legal_vacation_days} día${(editData.duration_days - vacationCalculationData.available_legal_vacation_days) > 1 ? "s" : ""}`
                                                    }
                                                </Typography>
                                            </Grid>
                                        }

                                        <TextField
                                            name={"comment"}
                                            margin={"none"}
                                            onInput={e => {
                                                e.target.value = e.target.value.toString().slice(0, 1024);
                                            }}
                                            multiline={true}
                                            rows={1}
                                            label={getLocalizedString("insertComment")}
                                            rowsMax={3}
                                        />
                                        <Typography
                                            style={{ paddingTop: 5 }}
                                            className={"whiteText"}
                                            variant="caption"
                                            gutterBottom>
                                            {`${getLocalizedString("optionalField")}. ${getLocalizedString("currentChar")} ${values.comment ? values.comment.length : 0}. ${getLocalizedString("maxCharRequests")
                                                }.`}
                                        </Typography>
                                        <DialogActions>
                                            <CustomButtonYes name="yesButton" variant="contained" onClick={() => form.change("accept", true)} type="submit">
                                                {getLocalizedString("approveRequest")}
                                            </CustomButtonYes>
                                            <CustomButtonNo name="noButton" variant="contained" onClick={() => form.change("accept", false)} type="submit">
                                                {getLocalizedString("rejectRequest")}
                                            </CustomButtonNo>
                                        </DialogActions>
                                    </form>
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
}

export default ReplyDialog;
