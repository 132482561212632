import React from "react";
import CommonPage from "@icarius-common/commonPage";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import Kanban from "@icarius-common/kanban";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import ProgressBar from "@icarius-pages/onboardingTracking/components/progressBar";
import Action from "@icarius-pages/onboardingTracking/components/action";
import ConsentDialog from "@icarius-common/consentDialog";
import ViewActionDialog from "@icarius-pages/onboardingTracking/components/viewActionDialog";
import FeedbackPublication from "@icarius-pages/onboardingTracking/components/feedbackPublication";
import {
  acceptMyConsentAction,
  createMyOnboardingTrackingActionCommentsAction,
  deleteMyOnboardingTrackingActionCommentsAction,
  previewMyConsentAction,
  updateMyOnboardingTrackingActionCommentsAction,
} from "../actions";
import useMyOnboardingTracking from "./useMyOnboardingTracking";
import useFeedback from "./useFeedback";

const MyOnboardingTracking = ({ history }) => {

  const {
    isLoading,
    state,
    handlers,
    processList,
    momentList,
    actionTypeList,
    actionList,
    comments: actionComments,
    files,
    progressData,
    employeeList,
    kanbanColumnDefinition,
  } = useMyOnboardingTracking();

  const {
    state: feedbackState,
    handlers: feedbackHandlers,
    people,
    groups,
    tasks,
    comments: feedbackComments,
    isLoadingFeedback,
  } = useFeedback();

  const color = useSelector(getAppColor);

  const getProgressValue = (progressObject) => {
    if (progressObject && progressObject.amountTotal > 0) return progressObject.amountInProgress * 100 / progressObject.amountTotal;
    return null;
  }

  const momentProgressValue = getProgressValue(progressData?.momentProgress);

  const getActionIsReadOnly = (action) => {
    if (action.status === "P") return true;
    return false;
  }

  const renderPieceContent = ({ data }) => {
    if (state.selectedActionType && data.type !== state.selectedActionType) return null;

    return (
      <Action
        data={data}
        collaboratorList={employeeList}
        responsibleOptionsList={employeeList}
        handleClick={handlers.handleOpenViewActionDialog}
        handleOpenMaterial={handlers.handleOpenMaterial}
        readOnly={getActionIsReadOnly(data)}
      />
    )
  }

  const isLoadingEverything = isLoading || isLoadingFeedback.gridData || isLoadingFeedback.privacyData || isLoadingFeedback.feed;

  return (
    <CommonPage
      isLoading={isLoadingEverything}
      title={"Mi proceso de onboarding"}
      isNotGridPage
    >
      <div style={{ padding: 24, paddingTop: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 20, minHeight: 60 }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end', gap: 10 }}>
            <div style={{ minWidth: 300, maxWidth: 300 }}>
              <FormControl fullWidth style={{ width: "100%" }}>
                <InputLabel required id={`label-process`}>{"Proceso"}</InputLabel>
                <Select
                  value={state.selectedProcess}
                  labelId={`label-process`}
                  id={`select-process`}
                  onChange={(e) => handlers.handleChangeProcess(e.target.value)}
                  margin={"none"}
                >
                  {
                    processList?.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: "flex-end", marginLeft: "auto" }}>
            <CustomIconButton
              title={getLocalizedString("goBack")}
              onClick={() => history.push(paths.myDesktop)}
              type={"goBack"}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <Divider style={{ margin: "20px 0px", background: color, height: 1 }} />
        </div>
        <div>
          <div style={{ display: "flex", alignItems: "flex-end", flexWrap: "wrap", gap: 10 }}>
            {
              state.selectedProcess &&
              <>
                <div style={{ minWidth: 300, maxWidth: 300 }}>
                  <FormControl fullWidth style={{ width: "100%" }}>
                    <InputLabel required id={`label-moment`}>{"Momento"}</InputLabel>
                    <Select
                      value={state.selectedMoment}
                      labelId={`label-moment`}
                      id={`select-moment`}
                      onChange={(e) => handlers.handleChangeMoment(state.selectedProcess, e.target.value)}
                      margin={"none"}
                    >
                      {
                        momentList?.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}
                          >
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
                <div style={{ minWidth: 300, maxWidth: 300 }}>
                  <FormControl fullWidth style={{ width: "100%" }}>
                    <InputLabel shrink id={`label-actionType`}>{"Tipo de acción"}</InputLabel>
                    <Select
                      value={state.selectedActionType}
                      labelId={`label-actionType`}
                      id={`select-actionType`}
                      onChange={(e) => handlers.handleChangeActionType(e.target.value)}
                      margin={"none"}
                      displayEmpty
                    >
                      <MenuItem className={"whiteText"} value={""}>
                        {"Todos"}
                      </MenuItem>
                      {
                        actionTypeList?.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}
                          >
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
              </>
            }
            {
              Boolean(progressData?.momentProgress) && state.selectedMoment &&
              <Tooltip
                title={`Progreso del momento: ${formatNumberExactDecimals(momentProgressValue, 2)}% (${progressData?.momentProgress.amountInProgress} de ${progressData?.momentProgress.amountTotal})`}
                arrow
              >
                <div style={{ minWidth: 125 }}>
                  <ProgressBar value={momentProgressValue} />
                </div>
              </Tooltip>
            }
          </div>
          <Kanban
            data={actionList}
            columnDefinition={kanbanColumnDefinition}
            handleChange={handlers.handleChangeBoardData}
            renderPieceContent={renderPieceContent}
            getIsDisabled={() => true}
          />
        </div>
      </div>
      {
        Boolean(state.viewDialogIsOpen) &&
        <ViewActionDialog
          open={Boolean(state.viewDialogIsOpen)}
          data={state.selectedAction}
          isLoading={isLoading}
          collaboratorList={[]}
          employeeList={employeeList}
          comments={actionComments}
          files={files}
          createCommentAction={createMyOnboardingTrackingActionCommentsAction}
          updateCommentAction={updateMyOnboardingTrackingActionCommentsAction}
          deleteCommentAction={deleteMyOnboardingTrackingActionCommentsAction}
          handleOpenFeedbackPublication={feedbackHandlers.handleOpenFeedbackPublication}
          handleClose={handlers.handleCloseViewActionDialog}
        />
      }
      {
        Boolean(state.selectedConsent) &&
        <ConsentDialog
          open={Boolean(state.selectedConsent)}
          data={state.selectedConsent}
          acceptAction={acceptMyConsentAction}
          previewAction={previewMyConsentAction}
          handleClose={handlers.handleCloseConsentDialog}
          readOnly={Boolean(state.selectedConsent?.acceptDate)}
        />
      }
      {
        Boolean(feedbackState.selectedFeedbackPublication) &&
        <FeedbackPublication
          isLoading={isLoadingFeedback}
          data={feedbackState.selectedFeedbackPublication}
          people={people}
          groups={groups}
          tasks={tasks}
          comments={feedbackComments}
          handleClose={feedbackHandlers.handleCloseFeedbackPublication}
        />
      }
    </CommonPage>
  )
}

export default MyOnboardingTracking;