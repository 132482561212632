import { getLocalizedString } from "@icarius-localization/strings";

const NAME = "MY_PENDING_PAGE";

export const tableColumns = [
  {
    id: "CODIGO DE EMPLEADO",
    dbColName: "U_idcodigo",
    numeric: false,
    label: getLocalizedString("code"),
  },
  {
    id: "APELLIDO Y NOMBRE",
    dbColName: "U_idapenom",
    numeric: false,
    label: getLocalizedString("nameAndSurname"),
  },
  {
    id: "FECHA DE INGRESO",
    dbColName: "U_ctfecing",
    numeric: false,
    date: true,
    label: getLocalizedString("income"),
  },
  {
    id: "CARGO",
    dbColName: "U_ctfecing",
    numeric: false,
    date: true,
    label: getLocalizedString("position"),
  },
  {
    id: "LUGAR DE TRABAJO",
    dbColName: "U_ctfecing",
    numeric: false,
    date: true,
    label: getLocalizedString("jobLocation"),
  },
  {
    id: "EMAIL",
    dbColName: "U_ctfecing",
    numeric: false,
    date: true,
    label: getLocalizedString("email"),
  },
  {
    id: "CELULAR",
    dbColName: "U_ctfecing",
    numeric: false,
    date: true,
    label: getLocalizedString("workPhone"),
  },
  {
    id: "EXTENSION TELEFONICA",
    dbColName: "U_ctfecing",
    numeric: false,
    date: true,
    label: getLocalizedString("phoneExtension"),
  },
];

export default NAME;
